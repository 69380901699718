import { Component } from '@angular/core';

@Component({
  selector: 'app-website-theme',
  templateUrl: './website-theme.component.html',
  styleUrls: [
    './website-theme.component.scss',
    './css/main.css',
    './css/custom-style-blocks.css',
    './css/imports.css'
  ]
})
export class WebsiteThemeComponent {

  style: any = 'ast-builder-menu-mobile-hide';
  toggle: any = '';

  action() {
    console.log('Yes');
    if (this.style == 'ast-builder-menu-mobile-show') {
      this.style = 'ast-builder-menu-mobile-hide';
      this.toggle = '';
    }
    else {
      this.style = 'ast-builder-menu-mobile-show';
      this.toggle = 'toggled';
    }
  }

}
