import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';

import { environment } from '@env/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private httpClient: HttpClient,
    public iconSet: IconSetService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (this.fileExists(environment.app.icon)) {
      this.favIcon.href = environment.app.icon;
    }
    this.title.setTitle(environment.app.name);
    this.meta.updateTag({ name: 'description', content: environment.app.name });
    this.meta.updateTag({ name: 'author', content: environment.app.name });
    this.meta.updateTag({ property: 'og:image', content: './' + environment.app.logo });
  }

  async fileExists(path): Promise<boolean> {
    let result = false;
    await this.httpClient.get(path, { responseType: 'text' }).toPromise().then(response => {
      result = true;
    }).catch(error => {
      console.log('error', error);
    });
    return result;
  }
}
