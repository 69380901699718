import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  private formatErrors(error: any) {
    console.log('error.error', error.error);
    if (error.error.error_code == 1001) {
      this.toastrService.error(error.error.message, 'Error');
      this.router.navigate(['/complete-profile']);
    }
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api.apiUrl}${path}`, { params })
      .pipe(catchError(error => this.formatErrors(error)));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${environment.api.apiUrl}${path}`,
      body
    ).pipe(catchError(error => this.formatErrors(error)));
  }

  post(path: string, body: object = {}, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams(), responseType?: any): Observable<any> {
    return this.http.post(
      `${environment.api.apiUrl}${path}`,
      body,
      { headers, params, responseType: responseType }
    ).pipe(catchError(error => this.formatErrors(error)));
  }

  delete(path: string, options: object = {}): Observable<any> {
    return this.http.delete(
      `${environment.api.apiUrl}${path}`,
      options
    ).pipe(catchError(error => this.formatErrors(error)));
  }

  download(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${environment.api.apiUrl}${path}`,
      body,
      { responseType: 'blob' }
    ).pipe(catchError(error => this.formatErrors(error)));
  }
  
}
