import { NgModule } from '@angular/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LaddaModule } from 'angular2-ladda';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { StripePaymentComponent } from '@app/components/stripe-payment/stripe-payment.component';
import { WebsiteThemeComponent } from '@app/components/website-theme/website-theme.component';

@NgModule({
  declarations: [
    StripePaymentComponent,
    WebsiteThemeComponent
  ],
  imports: [
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    FormsModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    LaddaModule,
    PopoverModule.forRoot()
  ],
  exports: [
    StripePaymentComponent,
    WebsiteThemeComponent
  ],
  providers: [

  ]
})
export class ComponentsModule { }
