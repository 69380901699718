import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth/auth.service';
import { UserService } from '@app/services/user/user.service';
import { ProfileResolver } from '@app/resolvers/profile.resolver';

import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems = null;

  profile: any;
  logo;
  time;
  currentYear: number = new Date().getFullYear();

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private profileResolver: ProfileResolver
  ) { }

  async ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
    this.profile = this.profileResolver.getProfile();
    if (this.profile) {
      if (this.profile.registered === 1) {
        this.router.navigate(['/complete-profile']);
      }
      if (this.profile.plan.validate_vest && this.profile.has_product_codes === 0) {
        this.router.navigate(['/shop/vests']);
      }
      this.navItems = this.navigationPermissions(navItems);
    } else {
      this.router.navigate(['/login']);
    }
  }

  navigationPermissions(navigationItems): any {
    const userPermissions = this.profile.web_app_menu_permissions;
    const navWithPermissions = [];
    if (userPermissions) {
      navigationItems.forEach(item => {
        const navItem = { ...item };
        if (navItem.permission) {
          if (navItem.permission === 'users' && this.profile.email !== this.profile.account.email) {
            return;
          }
          userPermissions.some(permission => {
            if (permission.name === navItem.permission) {
              navWithPermissions.push(navItem);
              return true;
            }
            return false;
          });
        }
      });
    }
    return navWithPermissions;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authService.logout()
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
  }

  updateUrl() {
    this.profile.photo = 'assets/img/avatars/user.png';
  }

}
