import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { CustomErrorComponent } from './views/error/custom-error.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'shop',
    children: [
      {
        path: '',
        loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule)
      }
    ]
  },
  {
    path: 'shop/:products',
    children: [
      {
        path: '',
        loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule)
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: {
          title: 'Users',
          permission: 'users'
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'session-analysis',
        loadChildren: () => import('./views/session-analysis/session-analysis.module').then(m => m.SessionAnalysisModule),
        data: {
          title: 'Session Analysis'
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: {
          title: 'Settings'
        }
      },
    ]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'error',
    component: CustomErrorComponent,
    data: {
      title: 'Error'
    }
  },
  { path: '**', component: P404Component }
];

if (window.location.origin.includes('store')) {
  routes.splice(0);
  routes.push({
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./views/shop/shop.module').then(m => m.ShopModule)
      }
    ]
  },
  { path: '**', component: P404Component }
  );
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
