import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '@env/environment';

@Component({
  selector: 'app-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['custom-error.component.scss'],
})
export class CustomErrorComponent implements OnInit {

  message: string;

  appName = environment.app.name;
  appLogo = environment.app.logo;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.message = this.route.snapshot.queryParams.message;
  }

}
