export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
    permission: 'dashboard'
  },
  {
    name: 'Team',
    url: '/users',
    icon: 'icon-note',
    permission: 'users'
  },
  // {
  //   name: 'Licenses',
  //   url: '/licenses',
  //   icon: 'icon-star',
  //   class: 'menu-icon-users',
  //   permission: 'licenses'
  // },
  {
    name: 'Session Analysis',
    url: '/session-analysis',
    icon: 'icon-grid',
    permission: 'session-analysis'
  },
  {
    name: 'Color Settings',
    url: '/settings',
    icon: 'icon-settings',
    permission: 'session-analysis'
  },
];
