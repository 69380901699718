import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Output, EventEmitter, OnDestroy, ViewChild, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

declare var Stripe: any;

@Component({
  selector: 'app-stripe-payment',
  styleUrls: ['./stripe-payment.component.scss'],
  templateUrl: './stripe-payment.component.html',
})
export class StripePaymentComponent implements AfterViewInit, OnDestroy {
  @Input() cardName: string;
  @Input() billingInfo: any;
  @Input() email: string;
  @Input() requireAcceptTerms: boolean = false;
  @Output() paymentId: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('cardNumber') cNumber: ElementRef;
  @ViewChild('cardExpiry') cExpiry: ElementRef;
  @ViewChild('cardCvc') cCvc: ElementRef;

  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;

  cardHandler = this.onChange.bind(this);
  cardNumberError: string;
  cardExpiryError: string;
  cardCvcError: string;

  stripe;
  elements;

  ladda: boolean = false;
  showEmail: boolean = false;
  acceptTerms: boolean = false;
  submitted: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private toastrService: ToastrService) { }

  ngAfterViewInit(): void {
    this.stripe = Stripe(environment.stripe.key);
    this.elements = this.stripe.elements();
    const elementStyles = {
      base: {
        color: '#000000',
        fontWeight: 400,
        fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF'
        }
      },
      invalid: {
        color: '#f86c6b',
        '::placeholder': {
          color: '#f86c6b',
        },
      },
    };
    this.cardNumber = this.elements.create('cardNumber', { style: elementStyles, classes: { base: 'form-control' } });
    this.cardNumber.mount(this.cNumber.nativeElement);
    this.cardExpiry = this.elements.create('cardExpiry', { style: elementStyles, classes: { base: 'form-control' } });
    this.cardExpiry.mount(this.cExpiry.nativeElement);
    this.cardCvc = this.elements.create('cardCvc', { style: elementStyles, classes: { base: 'form-control' } });
    this.cardCvc.mount(this.cCvc.nativeElement);
    this.cardNumber.addEventListener('change', this.cardHandler);
    this.cardExpiry.addEventListener('change', this.cardHandler);
    this.cardCvc.addEventListener('change', this.cardHandler);
    if (!this.email) {
      this.showEmail = true;
    }
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.cardNumber.removeEventListener('change', this.cardHandler);
    this.cardExpiry.removeEventListener('change', this.cardHandler);
    this.cardCvc.removeEventListener('change', this.cardHandler);
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }

  onChange(error) {
    try {
      this.cardNumberError = (error.error && error.elementType === 'cardNumber') ? error.error.message : null;
      this.cardExpiryError = (error.error && error.elementType === 'cardExpiry') ? error.error.message : null;
      this.cardCvcError = (error.error && error.elementType === 'cardCvc') ? error.error.message : null;
      this.changeDetector.detectChanges();
    }
    catch (error) {
      console.log('Error-onChange', error);
    }
  }

  validateAcceptTerms() {
    if (this.requireAcceptTerms) {
      if (!this.acceptTerms && this.submitted) {
        return true;
      }
    }
    return false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.requireAcceptTerms && !this.acceptTerms) {
      this.toastrService.error('You must accept the terms in order to continue.', 'Error');
      return;
    }
    this.ladda = true;
    const billingDetails = {
      address: {},
      email: this.email,
      name: this.cardName,
      phone: this.billingInfo.phone
    };
    if (this.billingInfo) {
      billingDetails.address = {
        city: this.billingInfo.city,
        country: this.billingInfo.country_code,
        line1: this.billingInfo.address,
        postal_code: this.billingInfo.zip_code,
        state: this.billingInfo.state,
      }
    }
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      billing_details: billingDetails
    }).then(result => {
      if (result.error) {
        this.ladda = false;
        this.toastrService.error(result.error.message, 'Error');
      } else {
        result.email = this.email;
        result.name = this.cardName;
        this.paymentId.emit(result);
      }
    }, error => {
      console.log('Error-onSubmit', error);
    });
  }
}
