export const environment = {
  production: false,
  api: {
    grant_type: 'password',
    apiUrl: 'https://api-basketball-kinetics-dev.viznperformance.com/',
    clientId: '2',
    secret: '4SqkN9LAi4KpqfGbbLJ6qwpm8YfXo27KZqsOvUR0',
    scope: '*'
  },
  app: {
    name: 'XrossOver Sports (Dev)',
    logo: 'assets/img/brand/logo_xross_over_sports.png',
    shop_logo: 'assets/img/brand/shop_logo_xross_over_sports.png',
    icon: 'assets/img/brand/icon_xross_over_sports.ico'
  },
  stripe: {
    key: 'pk_test_51M9BUUCGPbEKtLec2UaKXGc8fRF9sRGg6N7VfczBZEdkWFbLamEUq6YtOcaXxaU1nXSrCaiftRPnn7w6nCf5Nuv400cjk2wrLt'
  },
  recaptcha: {
    siteKey: '6LejsFwpAAAAAOBW9AFsCbgCsuQtkhss8oG1yQg7',
  }
};
