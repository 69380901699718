// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/views/error/custom-error.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AACJ","sourcesContent":[".center {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    width: 50%;\n    height: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
