import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { ApiService } from '../api/api.service';
import { HttpHeaders } from '@angular/common/http';
import { ProfileResolver } from '@app/resolvers/profile.resolver';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private profileService: ProfileResolver) { }

  public login(username, password) {
    const credentials = {
      'email': username,
      'password': password,
    };
    return new Observable(observable => {
      this.apiService.post('api/auth/login', credentials).subscribe(
        response => {
          this.cookieService.set('token', response['access_token']);
          observable.next(true);
          observable.complete();
        },
        error => {
          observable.error(error);
        }
      )
    });
  }

  public logout() {
    return new Observable(observable => {
      this.apiService.delete('api/logout')
        .subscribe(() => {
          this.cookieService.deleteAll();
          this.profileService.clearProfile();
          observable.next();
          observable.complete();
        },
          error => {
            observable.error(error);
          });
    })
  }

  forgotPassword(data: any) {
    return this.apiService.post('api/reset-password', data);
  }

  public setToken(token) {
    this.cookieService.set('token', token);
  }

  public getToken() {
    return this.cookieService.get('token');
  }

  public deleteToken() {
    this.cookieService.delete('token');
  }

  public isAuthenticated() {
    if (this.getToken())
      return true;
    return false;
  }

  checkEmail(data: any) {
    return this.apiService.get('api/check-existing-account', data);
  }

}
