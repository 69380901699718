import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.isAuthenticated()) {
            const request = req.clone({
                setHeaders: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
            return next.handle(request).pipe(catchError(
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        this.auth.deleteToken();
                        this.router.navigate(['/login']);
                    }
                    return throwError(error);
                }
            ));
        }
        return next.handle(req);
    }
}