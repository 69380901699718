import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urls = {
    user: 'api/user',
    informativeCards: 'api/user/informative-cards',
    // credentials: 'api/credentials',
    checkToken: 'api/check-token',
    changePassword: 'api/change-password',
    approveUser: 'api/approve-user',
    website: 'api/cake',
    account: 'api/account',
    check_existing_account: 'api/check-existing-account',
    recover_account: 'api/recover-account',
    feedback: 'api/feedback',
    team: 'api/team',
    userTeam: 'api/user-team'
  };

  constructor(
    private apiService: ApiService
  ) { }

  getProfile() {
    return this.apiService.get('api/profile');
  }

  getUsers(filter?) {
    return this.apiService.get(this.urls.user, filter);
  }

  getInformativeCards() {
    return this.apiService.get(this.urls.informativeCards);
  }

  getUser(id) {
    return this.apiService.get(`${this.urls.user}/${id}`);
  }

  saveUser(data) {
    return this.apiService.post(this.urls.user, data);
  }

  updateUser(id, data: any) {
    return this.apiService.put(`${this.urls.user}/${id}`, data);
  }

  deleteUser(id) {
    return this.apiService.delete(`${this.urls.user}/${id}`);
  }

  /*sendCredentials(id: any) {
    return this.apiService.put(`${this.urls.credentials}/${id}`);
  }*/

  checkToken(token: any) {
    return this.apiService.get(`${this.urls.checkToken}/${token}`);
  }

  resetPassword(data: any) {
    return this.apiService.put(this.urls.changePassword, data);
  }

  changePassword(data: any) {
    return this.apiService.post(`${this.urls.user}/change-password`, data);
  }

  sendInvitation(data: any) {
    return this.apiService.post(`${this.urls.user}/send-invitation`, data);
  }

  approveUser(data: any) {
    return this.apiService.put(this.urls.approveUser, data);
  }

  getWebsite() {
    return this.apiService.get(this.urls.website);
  }

  getAccounts() {
    return this.apiService.get(this.urls.account);
  }

  checkExistingAccount(data) {
    return this.apiService.post(this.urls.check_existing_account, data);
  }

  recoverAccount(data) {
    return this.apiService.post(this.urls.recover_account, data);
  }

  feedback(data: any) {
    return this.apiService.post(this.urls.feedback, data);
  }

  cancelAccount() {
    return this.apiService.delete(this.urls.user);
  }

  joinTeam(data: any) {
    return this.apiService.put(`${this.urls.userTeam}/join`, data);
  }

  leaveTeam(data: any) {
    return this.apiService.put(`${this.urls.userTeam}/leave`, data);
  }

}
